<template>
  <base-section id="aboutourproduct">
    <base-section-heading
      color="grey lighten-2"
      title="Servicios"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',
    data: () => ({
      cards: [
        {
          title: 'Timbres',
          // subtitle: 'Subtitulo',
          text: 'Contenido...',
          callout: '1',
        },
      ],
    }),
  }
</script>
